import React, { useEffect, useState } from "react";
import { db } from "../../Firebase/firebase-config";
import { collection, getDocs } from "firebase/firestore";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Sidebar1 from "./components/Sidebar1";
import Navbar1 from "./components/Navbar1";
import Footer from "./../Home/Components/Footer";
import { truncateString } from "../../helpers/stringHelpers";
import CoursePopup from "./components/CoursePopup";

const Courses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSidebarVisible, setSidebarVisible] = useState(false);

  const showSideBar = () => {
    setSidebarVisible(true);
  };

  const { id } = useParams();

  const tabName = "/ Grades / Courses";

  useEffect(() => {
    setLoading(true);
    document.title = "Courses - ICT Suhashi";
    const fetchCourses = async () => {
      try {
        const courseSnapshot = await getDocs(
          collection(db, `edu_level/${id}/courses`)
        );

        const courses = await courseSnapshot.docs.map((courseDoc) => ({
          course_id: courseDoc.id,
          course_data: courseDoc.data(),
          eduLevel: id,
        }));
        setCourses(courses);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };

    fetchCourses();
  }, [id]);

  return (
    <div className="flex h-screen">
      <Sidebar1 isVisible={isSidebarVisible} setVisible={setSidebarVisible} />
      <div className="flex-1 flex-grow mt-4 overflow-y-auto sm:mt-0 lg:mt-0">
        <Navbar1 TabName={tabName} onShowSideBar={showSideBar} />
        {loading ? (
          <div className="flex flex-col items-center mt-10">
            <div className="w-24 h-24 border-t-2 border-b-2 border-gray-900 rounded-full animate-spin"></div>
            <p className="mt-4 text-gray-900">Loading, please wait..</p>
          </div>
        ) : (
          ""
        )}
        {courses.length === 0 && !loading ? (
          <div className="flex flex-col items-center mt-10">
            <p>No data available. Please check back later.</p>
          </div>
        ) : (
          ""
        )}
        <div className="min-h-screen px-4 mx-auto my-2 ">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-5">
            {courses
              ?.sort((a, b) => {
                //Assuming 'dateTime' is a property of each edulevelDoc
                const dateTimeA = new Date(
                  `${a.course_data.date} ${a.course_data.time}`
                );
                const dateTimeB = new Date(
                  `${b.course_data.date} ${b.course_data.time}`
                );
                // return dateTimeB - dateTimeA;
                return dateTimeA - dateTimeB;
              })
              ?.map((course) => (
                <Link
                  className="w-full overflow-hidden border rounded-lg shadow-sm bg-card text-card-foreground"
                  to={`/chapters/${course.eduLevel}/${course.course_id}`}
                  key={course.course_id}
                >
                  <div className="pb-5 ">
                    <div className="data-grid">
                      <img
                        src={course.course_data.imageUrl}
                        alt="Student"
                        className="w-full h-auto"
                        width={200}
                        height={100}
                        style={{ aspectRatio: "200 / 100", objectFit: "fill" }}
                      />
                      <CoursePopup obj={course.course_data} />
                    </div>
                    <div className="mt-2 font-medium text-center">
                      {/* {course.course_data.name} */}
                      {truncateString(course.course_data.name, 20)}
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Courses;
